import React, { Component } from 'react'
import { Global } from '@emotion/core'
import styled from '@emotion/styled'
import Img from 'gatsby-image'

import { below } from '../../../../utils/media-query'
import Layout from '../../../../components/Layout'
import { creditReportGlobal } from '../../../../styles'
import createOffer from '../../../../utils/createOffer'

const querystring = require('querystring')

const CreditReportContainer = styled.div`
  .bannerImg {
    width: 100%;
    max-width: 28rem;
  }
  .contentBox {
    display: flex;
    .womanImg {
      align-self: flex-start;
    }
  }
  ${below.small`
    padding: 2rem;
    .contentBox {
      display: contents;
    }
  `}
`

class CreditReportMn extends Component {
  componentDidMount() {
    createOffer(querystring.parse(window.location.search.split('?')[1]), 'mn')
  }

  render() {
    const { data: { banner, woman } } = this.props
    return (
      <React.Fragment>
        <Global styles={creditReportGlobal} />
        <Layout offerPage>
          <CreditReportContainer>
            <Img
              className="bannerImg"
              fluid={banner.childImageSharp.fluid}
            />
            <div className="contentBox">
              <div id="offersTarget" />
              <Img
                className="womanImg"
                fixed={woman.childImageSharp.fixed}
              />
            </div>
          </CreditReportContainer>
        </Layout>
      </React.Fragment>
    )
  }
}

export default CreditReportMn

export const pageQuery = graphql`
  query AdvImages {
    banner: file(relativePath: { eq: "banner.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    woman: file(relativePath: { eq: "woman.png" }) {
      childImageSharp {
        fixed {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`